<template>
  <ElementTabs
    v-model="activeTeam"
    :tab-position="tabPosition"
    class="teams-page__tabs"
    @tab-click="handleTabChange"
  >
    <ElementTabPane
      v-for="team in mappedTeams"
      :key="team.id"
      :label="team.name"
    >
      <template v-if="team.isActive">
        <TeamUsersTable
          :team-id="team.id"
          :team-name="team.name"
        />
      </template>
    </ElementTabPane>
  </ElementTabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TeamUsersTable from '@/components/users/TeamUsersTable.vue'

export default {
  name: 'TeamsPage',

  components: {
    TeamUsersTable
  },

  data () {
    return {
      tabPosition: 'left',
      isLoadingTeams: false,
      activeTeam: null,
      setTeam: null,
      mappedTeams: null
    }
  },

  computed: {
    ...mapGetters({
      teams: 'getTeams'
    })
  },

  async created () {
    this.isLoadingTeams = true

    try {
      await this.fetchTeams()
      this.mappedTeams = this.mapTeams(this.teams)
    } catch (error) {
      if (error.response) {
        // this.errorStatus = error.response.status
        console.log('Error fetching the teams')
      } else {
        throw error
      }
    }

    this.isLoadingTeams = false
  },

  methods: {
    ...mapActions(['fetchTeams']),

    /**
     *
     */
    handleTabChange () {
      this.mappedTeams = this.mapTeams(this.teams)
      this.mappedTeams[this.activeTeam].isActive = true
    },

    /**
     * @param   {Array} teams
     *
     * @returns {Array}
     */
    mapTeams (teams) {
      const mappedTeams = teams.map(team => ({
        ...team,
        isActive: false
      }))

      mappedTeams[0].isActive = true

      return mappedTeams
    }
  }
}
</script>

<style lang="scss" scoped>
.teams-page__tabs {
  height: spacing(92);
}
</style>

<style lang="scss">
$tabs-nav-width: 300px;

.el-tabs__nav {
  width: $tabs-nav-width !important;
}
</style>
